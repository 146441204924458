<template>
  <span>  
    <font-awesome-icon :icon="['fas', 'plus']" v-on:click="toggleElement()" v-if="hide" style="cursor: pointer;"/>
    <font-awesome-icon :icon="['fas', 'minus']" v-on:click="toggleElement()" v-if="!hide" style="cursor: pointer;"/>
  </span>
</template>

<script>
export default {
  props: {
    hide:Boolean
  },
  methods: {
    toggleElement(){
      this.$emit('toggleElement')
    }
  }
}
</script>

<style scoped>
  svg {
    padding: 0.25rem;
    border: 1px solid slategrey;
    border-radius: 2rem;
  }

  svg:hover{
    padding: 0.25rem;
    background: rgb(208, 214, 221);
    border-radius: 2rem; 
  }

  svg:active{
    padding: 0.25rem;
    background: rgb(185, 203, 224);
    border-radius: 2rem; 
  }

  .hide{
    display: none;
}
</style>