<template>
  <div class="step-config-master-container">
    <div v-if="this.workflowStatus!=='abandoned' && this.workflowStatus!=='complete'" >
      <StepConfigEditableVue :project="this.project" :workflow_id="this.workflow_id" :step="this.step"/>
    </div>
    <div v-else>
      <StepConfigReadOnlyVue :project="this.project" :workflow_id="this.workflow_id" :step="this.step"/>
    </div>
  </div>  
</template>

<script>
import StepConfigEditableVue from './StepConfigEditable.vue'
import StepConfigReadOnlyVue from './StepConfigReadOnly.vue'

export default {
  components: {
    StepConfigEditableVue,
    StepConfigReadOnlyVue
  },
  props: {
    project: String,
    workflow_id: String,
    step: String,
    workflowStatus:String
  },
}
</script>
<style scoped>

</style>
