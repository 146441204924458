<template>
  <div class="step-config-master-container">
    <Notification />
    <h1>Step Config</h1>
    <h2 v-if="!stepConfigLoading && stepConfig.definition!==undefined" class="step-config-h2">{{stepConfig.definition.name}}</h2>
    <div v-if="stepConfigLoading">
      <h2><font-awesome-icon :icon="['fas', 'spinner']"/></h2>
      <h2 class="step-config-h2">Details</h2>
      <div>
        <div class="step-config-row">
          <div class="col">
            <div><strong>Name: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>
            <div><strong>Version: </strong>   <font-awesome-icon :icon="['fas', 'spinner']"/></div>  
          </div>
          <div class="col">
            <div><strong>Source: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>  
            <div><strong>Type: </strong><font-awesome-icon :icon="['fas', 'spinner']"/></div>
          </div>
        </div>
      </div>
      <h2 class="step-config-h2">Variables</h2> 
      <table id="step-config-table">
        <thead>
          <tr>
            <th>Name</th>
            <th>Source</th>
            <th>Type</th>
            <th>Version</th>
          </tr>
        </thead>
        <tbody v-if="stepConfig==undefined && !stepConfigLoading">        
          <tr>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>  
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
            <td><font-awesome-icon :icon="['fas', 'spinner']"/></td>
          </tr>
        </tbody>
      </table>           
    </div>
    <div v-if="!stepConfigLoading && stepConfig!==undefined && stepConfig.definition!==undefined"  class="step-config-container">
      <h2 class="step-config-h2">Details</h2>
      <form @submit.prevent="saveStepConfig">
        <button type="submit" class="app-button primary-button">Save step Configuration</button>
        <button type="button" class="app-button secondary-button" @click="resetAllOVerrides">Clear Step Overrides</button>
        <div class="step-config-row">
          <div class="col">
            <div><strong>Name: </strong>{{ stepConfig.definition.name  }} </div>
            <div class="step-config-input">
              <strong><label for="step-config-version">Version: </label></strong>
              <input type="text" v-model="stepConfig.definition.version" name="param-name-input" class="form-control" @change="handleUpdateVersion"/> 
              <h2 v-if="stepConfig.definition.source==='step_override'" @click="() => handleRemoveStepVersionOverride(variable)"><font-awesome-icon :icon="['fas', 'trash']"/></h2>
            </div>
          </div>
          <div class="col">
            <div><strong>Source: </strong>{{ stepConfig.definition.source  }} </div>
            <div><strong>Type: </strong>{{ stepConfig.definition.type  }}</div>
          </div>
        </div>
        <h2 class="step-config-h2">Variables</h2> 
        <table id="step-config-table">
          <thead>
            <tr>
              <td>{{ this.hide? "Add New Override":"Close" }}
                <Collapser @toggleElement="toggleElement" :hide="this.hide"/>
              </td>
            </tr>
            <tr>
              <th>Name</th>
              <th>Source</th>
              <th>Type</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>        
            <tr v-if="!hide">
              <td><input type="text" v-model="newStepOverride.name" class="form-control"/></td>
              <td>step_override</td>
              <td>str</td>
              <td>
                <input type="text" v-model="newStepOverride.value" class="form-control"/>
                <button v-if="newStepOverride.name!==undefined && newStepOverride.value!==undefined" @click="addNewStepOverride" type="button" class="app-button primary-button">Add New Override</button>
              </td>
            </tr>
            <tr v-if="addingNewStepOverride"><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='step_override')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='optional')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='step_var')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='static')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='auto')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='input')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-for="variable, index in stepConfig.variables.filter(stepVar => stepVar.source==='output')" :key="index">
              <td>{{variable.name}}</td>  
              <td>{{variable.source}}</td>
              <td>{{variable.type}}</td>
              <td v-if="(variable.source==='step_var'|| variable.source==='step_override') && variable.type!=='ReplaceOperation' && variable.type!=='str_sub' && variable.options===undefined">
                <div class="step-config-input">
                  <input type="text" v-model="variable.value" class="form-control" @change="(value) => handleUpdateValue({value, variable})"/>
                </div>
              </td>
              <td v-if="(variable.source!=='step_var' || variable.type==='ReplaceOperation' || variable.type==='str_sub') && variable.source!=='step_override' && variable.options===undefined">{{variable.value}}</td>
              <td v-if="variable.options!==undefined">
                <select id="filter"
                        name="filter"
                        class="form-control mt-1"
                        v-model="variable.value"
                        @change="(value) => handleUpdateValue({value, variable})">
                  <option v-for="item, index in variable.options" v-bind:value="item" :key="index">{{ item }}</option>
                </select>
              </td>
              <td class="remove-step-override" v-if="variable.source==='step_override'" @click="() => handleRemoveStepOverride(variable)">
                <h2><font-awesome-icon :icon="['fas', 'trash']"/></h2>
              </td>
            </tr>
            <tr v-if="addingNewStepOverride"><font-awesome-icon :icon="['fas', 'spinner']"/></tr>
          </tbody>
        </table>
        <button type="submit" class="app-button primary-button">Save Step Configuration</button>
      </form>
    </div>
  </div>  
</template>

<script>
import {mapGetters, mapActions} from 'vuex'
import SelectList from '../Generics/SelectList.vue'
import Collapser from '../Generics/Collapser.vue'
import Notification from '../Generics/Notification.vue';


export default {
  name: 'StepConfigEditable',
  components: {
    SelectList,
    Notification,
    Collapser
  },
  props: {
    project: String,
    workflow_id: String,
    step: String,
  },
  data() {
    return {
      hide:true,
      newStepOverride: {},
    }
  },
  computed: {
    ...mapGetters({
      stepConfig: 'getStepConfig',
      stepConfigLoading: 'stepConfigLoading',
      variablesToOverride: 'getStepOverrides',
      addingNewStepOverride: 'getAddingNewStepOverride'
    })
  },
  methods: {
    ...mapActions([
      'loadStepConfig', 
      'updateStepConfig', 
      'saveStepConfig', 
      'addStepConfigOverride', 
      'removeStepOverride',
      'resetAllOVerrides',
      'resetVersionOverride',
      'updateVersion'
    ]),
    async handleUpdateValue(selection){
      await this.updateStepConfig({"name":selection.variable.name.toUpperCase(), "value": selection.value.target.value})
    },
    async handleSaveStepConfig(){
      await this.saveStepConfig()
    },
    async handleRemoveStepVersionOverride(){
      await this.resetVersionOverride()
    },
    async handleUpdateVersion(){
      await this.updateVersion()
    },
    toggleElement(){
      this.hide = !this.hide
    },
    addNewStepOverride(){
      this.addStepConfigOverride(this.newStepOverride)
      this.toggleElement()
      window.scrollTo(0, document.body.scrollHeight);
    },
    handleRemoveStepOverride(variable){
      this.removeStepOverride(variable)
    }
  },
  async mounted(){
    let args = {project: this.project, workflow_id: this.workflow_id, step: this.step}
    await this.loadStepConfig(args)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
a {
  text-decoration: none;
}

.loading-component {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
}

.col{
    flex:1;
    flex-wrap: wrap;
    justify-content: flex-start;
    flex-direction: column;
    box-sizing: border-box;
    line-height: 2.5;
  }
  
  .step-config-container {
    text-align: center;
  }

  .step-config-row{
    display: flex;
    text-align: center;
    border: 0.5px solid lightgray;
    width:90%;
    margin-left:5%
  }

  .step-config-input{
    display: flex;
    text-align: center;
  }

  .step-config-row:nth-child(odd) {
  background-color: rgb(0, 75, 100, 0.05);
  }
  .step-config-row:hover{
    background-color: rgba(100, 105, 110, 0.1);
    cursor:pointer;
  }

  .step-config-h2 {
    padding-top:0.5rem;
    padding-bottom:1rem;
    margin:1rem;
    background: rgba(0, 0, 0, 0.01);
    border:0.1rem solid rgba(0, 0, 0, 0.03);
    border-radius:0.25rem;
    color:rgb(50, 50, 50)
  }

.step-config-master-container{
  padding-bottom:5rem;
}

.remove-step-override{
  cursor: pointer;
}

#filter{
  width: 100%;
  line-height: 1.5;
  text-align: center;
  height: 2rem;
  font-size: 16px;
  background-color: rgb(255, 255, 255);
  color:rgba(0, 0, 0, 0.829);
  border: 1px solid rgb(255, 255, 255);
  border-bottom: 2px solid rgb(75, 110, 75);
  border-radius: 4px;
  box-sizing: border-box;
  cursor: pointer;
}
#filter select{
   border: 1px solid rgb(255, 255, 255);
  -moz-appearance:none; /* Firefox */
  -webkit-appearance:none; /* Safari and Chrome */
  appearance:none;
}

td:hover{
  background-color: rgb(255, 255, 255);
  cursor: pointer;
}

</style>
